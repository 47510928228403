import axios from "axios"
import AuthToken from "@/auth/auth-token"

class Documents {
    constructor() {
      const base = axios.create({
        baseURL: process.env.VUE_APP_DOCUMENT_API,
        withCredentials: false,
        headers: {
          'Content-type': 'application/json'
        }
      })
      this.documents = base
    }

    async registerDocument(document) {
      await AuthToken.setAuth(this.documents, {
        audience: process.env.VUE_APP_DOCUMENT_AUDIENCE
      })
      return this.documents({
        url: '/doc/register',
        method: 'POST',
        data: document,
      })
    }

      async registerDocParty(partyID, docID) {
        await AuthToken.setAuth(this.documents, {
          audience: process.env.VUE_APP_DOCUMENT_AUDIENCE
        })
        return this.documents({
          url: `/doc/${docID}/party`,
          method: 'POST',
          data:{"party_id": partyID},
        })
      }

    async searchDoc(tags){
      await AuthToken.setAuth(this.documents, {
        audience: process.env.VUE_APP_DOCUMENT_AUDIENCE
      })
      return this.documents({
        url: '/docs/search',
        method: 'GET',
        params:{
          root_key: 'ipro_portal',
          tags: JSON.stringify(tags)
        },
      })
    }

    async deleteDoc(docId){
      await AuthToken.setAuth(this.documents, {
        audience: process.env.VUE_APP_DOCUMENT_AUDIENCE
      })
        return this.documents({
          url: `doc/${docId}`,
          method: 'DELETE'
        })
      }
}

export default new Documents()