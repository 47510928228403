<template>
  <v-menu
    :close-on-content-click="false"
    :nudge-bottom="3"
    :nudge-left="75"
    :value="searchMenu"
    bottom
    offset-y>
    <template #activator="{ on: menu, attrs }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            small
            @click="searchMenu = true"
            class="grey lighten-2 ml-2"
            height="38"
            v-bind="attrs"
            v-on="{ ...tooltip, ...menu }">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
        <span>{{ batchSearch ? 'Search' : 'Search' }}</span>
      </v-tooltip>
    </template>
    <v-card class="fill" max-width="235">
      <v-card-title class="pb-0 text-subtitle-1"> Search </v-card-title>
      <v-form class="pt-2 px-4" ref="form">
        <v-textarea
          :rules="[v => !!v || 'Requires at least one value']"
          @click:clear="$emit('clear')"
          background-color="input"
          class="mt-2"
          clearable
          dense
          no-resize
          outlined
          placeholder="Search By UPC, Item ID, or Linked Group"
          rows="10"
          type="number"
          v-model="searchValues">
        </v-textarea>
      </v-form>
      <v-card-actions class="fill">
        <v-spacer />
        <v-btn :class="body" text @click="searchMenu = false"> Cancel </v-btn>
        <v-btn :class="body" @click="search" class="green white--text">
          Search
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { displayHelpers } from '@/mixins/displayHelpers'
import { shared } from '@/mixins/shared'

export default {
  name: 'ItemSearch',
  mixins: [displayHelpers, shared],
  data() {
    return {
      searchMenu: false,
      searchValues: ''
    }
  },
  computed: {
    batchSearch() {
      return this.$route.name === 'BatchList'
    }
  },
  methods: {
    search() {
      const values = this.searchValues.split('\n')
      this.$emit('search', values)
      this.searchMenu = false
      this.$refs.form.reset()
    }
  }
}
</script>
