const dateConfig = {
  date_display: 'M/D/YYYY',
  date_format: 'YYYY-MM-DD',
  timestamp: 'MMM D - h:mm a',
  currentDate: new Date().toISOString().substr(0, 10),
  validDateFormats: [
    'M/D/YY',
    'M/D/YYYY',
    'M/DD/YY',
    'M/DD/YYYY',
    'MM/D/YY',
    'MM/DD/YYYY',
    'YY-M-DD',
    'YY-MM-D',
    'YY-MM-DD',
    'YYYY-M-DD',
    'YYYY-MM-D',
    'YYYY-MM-DD',
  ]
}

export default dateConfig