const host = window.location.hostname

// iPro tenant config
const iprosystems = {
  domain: process.env.VUE_APP_IPRO_DOMAIN,
  clientId: process.env.VUE_APP_IPRO_CLIENT_ID
}

// tenants outside of iPro
const tenantConfigs = [
  {
    hostId: 'houchens',
    domain: process.env.VUE_APP_HOUCHENS_DOMAIN,
    clientId: process.env.VUE_APP_HOUCHENS_CLIENT_ID
  },
  {
    hostId: 'awg',
    domain: process.env.VUE_APP_AWG_DOMAIN,
    clientId: process.env.VUE_APP_AWG_CLIENT_ID
  },
  {
    hostId: 'spn',
    domain: process.env.VUE_APP_SPN_DOMAIN,
    clientId: process.env.VUE_APP_SPN_CLIENT_ID
  },
  {
    hostId: 'arg',
    domain: process.env.VUE_APP_ARG_DOMAIN,
    clientId: process.env.VUE_APP_ARG_CLIENT_ID
  },
  {
    hostId: 'pce',
    domain: process.env.VUE_APP_PCE_DOMAIN,
    clientId: process.env.VUE_APP_PCE_CLIENT_ID
  },
  {
    hostId: 'jbg',
    domain: process.env.VUE_APP_JBG_DOMAIN,
    clientId: process.env.VUE_APP_JBG_CLIENT_ID
  }
]

function getTenantSettings () {
  // check current url hostname for tenant identifiers
  for (const config of tenantConfigs) {
    if (host.includes(`${config.hostId}`)) {
      return config
    }
  }
  // default to iPro tenant
  return iprosystems
}

const { domain, clientId } = getTenantSettings()
const audience = process.env.VUE_APP_API_AUDIENCE

export { domain, clientId, audience }
