var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", width: "500" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "text-subtitle-1" },
            [
              _c(
                "v-icon",
                { staticClass: "red--text mr-2", attrs: { size: "50" } },
                [_vm._v("mdi-alert-octagon")]
              ),
              _vm._v(" Overwrite TPR changes? "),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c("v-col", { attrs: { cols: "10" } }, [
                    _vm._v(
                      " This action will sync with the promotion portal to retrieve promotions that have a start date matching "
                    ),
                    _c("b", { staticClass: "primary--text" }, [
                      _vm._v(_vm._s(_vm.formatDateDisplay(_vm.batch.tpr_date))),
                    ]),
                    _vm._v(
                      " and overwrite any changes in the selected TPR batch. "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c("v-col", { attrs: { cols: "10" } }, [
                    _vm._v(" Click "),
                    _c("b", [_vm._v("Confirm")]),
                    _vm._v(" to begin sync. "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  class: _vm.body,
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("update:value", false)
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "green white--text",
                  class: _vm.body,
                  attrs: { loading: _vm.loading },
                  on: { click: _vm.confirm },
                },
                [_vm._v(" Confirm ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }