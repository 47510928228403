<template>
    <v-dialog justify="center" :value="value" persistent max-width="400">
      <v-card color=#fafafa>
        <section class="ma-0 pa-6">
          <v-row justify="center">
            <v-icon x-large color="red">mdi-trash-can-outline</v-icon>
          </v-row>
          <v-row justify="center">
              <span class='pa-4'>
              Delete "{{batch.batch_name}}"?
              </span>
              <span class='pa-4'>
              Last Updated: {{formatDateDisplay(batch.updated_on)}}
              </span>
              <span class='pa-4'>
              Warning: This action cannot be undone.
              </span>
          </v-row>
          <v-row justify="center" class="pa-4">
            <v-btn height="40" class="elevation-1" @click.stop="$emit('close')">
              No
            </v-btn>
            <v-btn color="#0c67a5" height="40" class="white--text ml-2"
              @click="deleteBatch(batch.id)">Yes
            </v-btn>
          </v-row>
        </section>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import { displayHelpers } from '@/mixins/displayHelpers'
  import { notification } from '@/mixins/notification'
  import Batches from '@/axios/batches'
  export default {
    name: 'DeleteBatchDialog',
    props: { value: Boolean, batch: Object },
    mixins: [displayHelpers, notification],
    methods: {
      async deleteBatch () {
          try {
            await Batches.deleteBatch(this.batch.id)
            this.notify(`Batch Deleted Successfully`)
          } catch (err) {
            console.error(err)
            const message = err?.response?.data?.message || err.message
            this.alert(`Error Delete Batch Name: ${message}`)
          } finally {
            this.loading = false
            this.$emit('update')
            this.$emit('close')
          }
        }
      }
  }
  </script>