import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Third Party
import vuetify from './plugins/vuetify'
import moment from 'moment'
import dateConfig from './config/date-config'

// Auth0
import {
  domain,
  clientId,
  audience
} from './auth/auth0-config.js'
import {
  Auth0Plugin
} from './auth/auth0-plugin'

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl ?
      appState.targetUrl :
      window.location.pathname
    )
  }
})

Vue.config.productionTip = false

Vue.prototype.moment = moment
Vue.prototype.$dateConfig = dateConfig

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')