var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c("v-data-table", {
        staticStyle: { cursor: "pointer" },
        attrs: {
          headers: _vm.headers,
          height: _vm.tableSize,
          "items-per-page": _vm.pageSize,
          items: _vm.batches,
          search: _vm.search,
          loading: _vm.loading,
          dense: "",
          "fixed-header": "",
          "hide-default-footer": "",
          "item-key": "id",
        },
        on: { "click:row": _vm.viewReport },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function () {
                return [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", color: "toolbar" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pl-0 pr-2",
                          attrs: { md: "3", lg: "3", xl: "2" },
                        },
                        [
                          !_vm.isTprProRetailUser
                            ? _c("v-autocomplete", {
                                staticClass: "mt-7 ma-0",
                                attrs: {
                                  items: _vm.userAdGroups,
                                  "background-color": "input",
                                  dense: "",
                                  "item-text": "name",
                                  "item-id": "id",
                                  label: "Ad Group",
                                  "menu-props": { maxHeight: 210 },
                                  outlined: "",
                                  "return-object": "",
                                },
                                on: { change: _vm.getBatches },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _vm.userGroupsLoading
                                            ? _c("v-progress-circular", {
                                                attrs: {
                                                  indeterminate: "",
                                                  size: "25",
                                                  color: "primary",
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3820389368
                                ),
                                model: {
                                  value: _vm.adGroup,
                                  callback: function ($$v) {
                                    _vm.adGroup = $$v
                                  },
                                  expression: "adGroup",
                                },
                              })
                            : _c("v-autocomplete", {
                                staticClass: "mt-7 ma-0",
                                attrs: {
                                  items: _vm.userStores,
                                  "background-color": "input",
                                  dense: "",
                                  "item-text": "store_name",
                                  "item-id": "store_party_id",
                                  label: "Store",
                                  "menu-props": { maxHeight: 210 },
                                  outlined: "",
                                  "return-object": "",
                                },
                                on: { change: _vm.getStoreBatches },
                                model: {
                                  value: _vm.store,
                                  callback: function ($$v) {
                                    _vm.store = $$v
                                  },
                                  expression: "store",
                                },
                              }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pl-0 pr-2",
                          staticStyle: { "max-width": "255px" },
                          attrs: { mx: "0", md: "2", lg: "2", xl: "4" },
                        },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "menu",
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-left": 5,
                                bottom: "",
                                "min-width": "auto",
                                "offset-y": "",
                                transition: "scale-y-transition",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                label: _vm.batchDateLabel,
                                                value: _vm.dateText,
                                                clearable: "",
                                                "background-color": "input",
                                                dense: "",
                                                readonly: "",
                                                "hide-details": "",
                                                outlined: "",
                                                "prepend-inner-icon":
                                                  "mdi-calendar-range",
                                              },
                                              on: {
                                                "click:clear":
                                                  _vm.clearBatchDate,
                                              },
                                            },
                                            "v-text-field",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.menu,
                                callback: function ($$v) {
                                  _vm.menu = $$v
                                },
                                expression: "menu",
                              },
                            },
                            [
                              !_vm.isTprProRetailUser
                                ? _c(
                                    "v-date-picker",
                                    {
                                      attrs: {
                                        "show-current": false,
                                        max:
                                          _vm.batchEndDate !== _vm.batchDate
                                            ? _vm.batchEndDate
                                            : null,
                                        "no-title": "",
                                      },
                                      on: { change: _vm.getBatches },
                                      model: {
                                        value: _vm.batchDate,
                                        callback: function ($$v) {
                                          _vm.batchDate = $$v
                                        },
                                        expression: "batchDate",
                                      },
                                    },
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          class: _vm.body,
                                          attrs: { text: "" },
                                          on: {
                                            click: function ($event) {
                                              _vm.batchDate = ""
                                            },
                                            blur: _vm.getBatches,
                                          },
                                        },
                                        [_vm._v(" Clear ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-date-picker",
                                    {
                                      attrs: {
                                        "show-current": false,
                                        max:
                                          _vm.batchEndDate !== _vm.batchDate
                                            ? _vm.batchEndDate
                                            : null,
                                        "no-title": "",
                                      },
                                      on: { change: _vm.getStoreBatches },
                                      model: {
                                        value: _vm.batchDate,
                                        callback: function ($$v) {
                                          _vm.batchDate = $$v
                                        },
                                        expression: "batchDate",
                                      },
                                    },
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          class: _vm.body,
                                          attrs: { text: "" },
                                          on: {
                                            click: function ($event) {
                                              _vm.batchDate = ""
                                            },
                                            blur: _vm.getStoreBatches,
                                          },
                                        },
                                        [_vm._v(" Clear ")]
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.tprDateAsRange
                        ? _c(
                            "v-col",
                            {
                              staticClass: "pl-0 pr-2",
                              staticStyle: { "max-width": "255px" },
                              attrs: { ml: "5", md: "2", lg: "2", xl: "4" },
                            },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "endMenu",
                                  attrs: {
                                    "close-on-content-click": false,
                                    "nudge-left": 5,
                                    bottom: "",
                                    "min-width": "auto",
                                    "offset-y": "",
                                    transition: "scale-y-transition",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-text-field",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      label: "Batch End Date",
                                                      value: _vm.endDateText,
                                                      clearable: "",
                                                      readonly: "",
                                                      "background-color":
                                                        "input",
                                                      dense: "",
                                                      "hide-details": "",
                                                      outlined: "",
                                                      "prepend-inner-icon":
                                                        "mdi-calendar-range",
                                                    },
                                                    on: {
                                                      "click:clear":
                                                        _vm.clearEndDate,
                                                    },
                                                  },
                                                  "v-text-field",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2739944284
                                  ),
                                  model: {
                                    value: _vm.endMenu,
                                    callback: function ($$v) {
                                      _vm.endMenu = $$v
                                    },
                                    expression: "endMenu",
                                  },
                                },
                                [
                                  _c(
                                    "v-date-picker",
                                    {
                                      attrs: {
                                        min: _vm.batchDate,
                                        "show-current": false,
                                        "no-title": "",
                                      },
                                      on: { change: _vm.getBatches },
                                      model: {
                                        value: _vm.batchEndDate,
                                        callback: function ($$v) {
                                          _vm.batchEndDate = $$v
                                        },
                                        expression: "batchEndDate",
                                      },
                                    },
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          class: _vm.body,
                                          attrs: { text: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clearEndText()
                                            },
                                          },
                                        },
                                        [_vm._v(" Clear ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-spacer"),
                      _vm.isFiltered
                        ? _c(
                            "v-menu",
                            {
                              attrs: {
                                bottom: "",
                                "offset-y": "",
                                "open-on-hover": "",
                                "max-height": "300",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-chip",
                                          _vm._g(
                                            {
                                              staticClass:
                                                "orange white--text ml-2",
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "text-capitalize",
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: {
                                                      color: "white",
                                                      small: "",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " mdi-filter-outline "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  " Results for " +
                                                    _vm._s(
                                                      _vm.searchValues.length
                                                    ) +
                                                    " search values "
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "white--text ml-2",
                                                                attrs: {
                                                                  icon: "",
                                                                  "x-small": "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.searchValues =
                                                                        []
                                                                    },
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-close-circle"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Clear Filter"),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1360570854
                              ),
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "text-subtitle-2 pb-0" },
                                    [_vm._v(" Search Values: ")]
                                  ),
                                  _c(
                                    "v-list",
                                    _vm._l(
                                      _vm.searchValues,
                                      function (value, index) {
                                        return _c(
                                          "v-list-item",
                                          { key: index },
                                          [_vm._v(" " + _vm._s(value) + " ")]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-spacer"),
                      !_vm.isTprProRetailUser
                        ? _c("ItemSearch", {
                            on: {
                              search: function ($event) {
                                _vm.searchValues = $event
                              },
                              clear: function ($event) {
                                return _vm.getBatches()
                              },
                            },
                          })
                        : _c("ItemSearch", {
                            on: {
                              search: function ($event) {
                                _vm.searchValues = $event
                              },
                              clear: function ($event) {
                                return _vm.getStoreBatches()
                              },
                            },
                          }),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-bottom": 3,
                            "nudge-left": 40,
                            value: _vm.createMenu,
                            bottom: "",
                            "offset-y": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on: menu, attrs }) {
                                return [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on: tooltip }) {
                                              return [
                                                !_vm.isTprProRetailUser
                                                  ? _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "grey lighten-2 ml-2",
                                                            class: _vm.body,
                                                            attrs: {
                                                              small: "",
                                                              height: "38",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.createMenu = true
                                                              },
                                                            },
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        { ...tooltip, ...menu }
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-folder-plus-outline"
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [_c("span", [_vm._v("Create Batch")])]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "v-card",
                            { staticClass: "fill" },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "pb-0 text-subtitle-1" },
                                [_vm._v(" Create TPR Batch ")]
                              ),
                              _c(
                                "v-form",
                                { ref: "form", staticClass: "fill" },
                                [
                                  _c("v-text-field", {
                                    staticClass: "pt-4 px-4",
                                    attrs: {
                                      rules: [(v) => !!v || "Name Required"],
                                      "background-color": "input",
                                      clearable: "",
                                      dense: "",
                                      label: "Batch Name",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.batchName,
                                      callback: function ($$v) {
                                        _vm.batchName = $$v
                                      },
                                      expression: "batchName",
                                    },
                                  }),
                                  _vm.showTprSrpUpload
                                    ? _c("v-autocomplete", {
                                        staticClass: "px-4",
                                        attrs: {
                                          rules: [
                                            (v) => !!v || "Batch Type Required",
                                          ],
                                          items: _vm.batchTypes,
                                          "background-color": "input",
                                          outlined: "",
                                          dense: "",
                                          clearable: "",
                                          label: "Batch Type",
                                        },
                                        model: {
                                          value: _vm.batchType,
                                          callback: function ($$v) {
                                            _vm.batchType = $$v
                                          },
                                          expression: "batchType",
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "ma-0 pa-0" },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "px-4 pb-0",
                                                attrs: {
                                                  rules: [
                                                    (v) =>
                                                      !!v || "Date Required",
                                                  ],
                                                  value: _vm.dateText,
                                                  "background-color": "input",
                                                  clearable: "",
                                                  readonly: "",
                                                  dense: "",
                                                  label: _vm.batchDateLabel,
                                                  outlined: "",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.tprDateAsRange
                                            ? _c(
                                                "v-col",
                                                { staticClass: "ma-0 pa-0" },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "px-4 pb-0",
                                                    attrs: {
                                                      rules: [
                                                        (v) =>
                                                          !!v ||
                                                          "Date Required",
                                                      ],
                                                      value: _vm.endDateText,
                                                      "background-color":
                                                        "input",
                                                      clearable: "",
                                                      readonly: "",
                                                      dense: "",
                                                      label: "Batch End Date",
                                                      outlined: "",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c("v-date-picker", {
                                    attrs: {
                                      "allowed-dates": _vm.allowedDates,
                                      max: _vm.batchEndDate || null,
                                      "show-current": false,
                                      color: "primary",
                                      "no-title": "",
                                      width: "326",
                                    },
                                    model: {
                                      value: _vm.batchDate,
                                      callback: function ($$v) {
                                        _vm.batchDate = $$v
                                      },
                                      expression: "batchDate",
                                    },
                                  }),
                                  _vm.tprDateAsRange
                                    ? _c("v-date-picker", {
                                        attrs: {
                                          min: _vm.batchDate,
                                          "allowed-dates": _vm.allowedEndDates,
                                          "show-current": false,
                                          color: "primary",
                                          "no-title": "",
                                          width: "326",
                                        },
                                        model: {
                                          value: _vm.batchEndDate,
                                          callback: function ($$v) {
                                            _vm.batchEndDate = $$v
                                          },
                                          expression: "batchEndDate",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-card-actions",
                                { staticClass: "fill", attrs: { flat: "" } },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      class: _vm.body,
                                      attrs: { text: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.createMenu = false
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "green white--text",
                                      class: _vm.body,
                                      on: { click: _vm.batchCreate },
                                    },
                                    [_vm._v(" Create ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.showTprSrpUpload
                        ? _c("TprSrpUpload", {
                            attrs: {
                              value: _vm.tprSrpUploadMenu,
                              baseAdGroup: _vm.adGroup,
                            },
                            on: { close: _vm.closeTprSrpUploadMenu },
                          })
                        : _vm._e(),
                      _c(
                        "v-col",
                        {
                          staticClass: "pl-2 pr-0",
                          attrs: { md: "3", lg: "3", xl: "4" },
                        },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-7",
                            attrs: {
                              "background-color": "input",
                              dense: "",
                              label: "Filter Batches",
                              outlined: "",
                              "prepend-inner-icon": "mdi-filter-outline",
                            },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                ]
              },
              proxy: true,
            },
            {
              key: `item.batch_type`,
              fn: function ({ item }) {
                return [
                  item.batch_type
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "mr-4",
                          attrs: {
                            small: "",
                            dark: "",
                            color: _vm.getColor(item.batch_type),
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                display: "inline-block",
                                margin: "0 auto",
                                width: "120px",
                                "text-align": "center",
                              },
                            },
                            [_c("span", [_vm._v(_vm._s(item.batch_type))])]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.tpr_date`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(_vm._s(_vm.formatDateDisplay(item.tpr_date))),
                  ]),
                ]
              },
            },
            {
              key: `item.tpr_end_date`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(_vm._s(_vm.formatDateDisplay(item.tpr_end_date))),
                  ]),
                ]
              },
            },
            {
              key: `item.created_on`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(_vm._s(_vm.formatDateDisplay(item.created_on))),
                  ]),
                ]
              },
            },
            {
              key: `item.updated_by`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.updated_by !== "None" ? item.updated_by : "---"
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.updated_on`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.updated_on
                            ? _vm.formatDateDisplay(item.updated_on)
                            : "---"
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.status`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-chip",
                    {
                      staticClass: "mr-4",
                      attrs: {
                        small: "",
                        dark: "",
                        color: _vm.getColor(item.status),
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "inline-block",
                            margin: "0 auto",
                            width: "90px",
                            "text-align": "center",
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(item.status))])]
                      ),
                    ]
                  ),
                ]
              },
            },
            {
              key: `item.actions`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mr-4",
                                      attrs: {
                                        disabled:
                                          !_vm.isBatchAvailable.includes(
                                            item.status
                                          ) || _vm.isSyncing,
                                        icon: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.selectedBatch = item
                                        },
                                      },
                                    },
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        _vm._l(_vm.actions, function (action, index) {
                          return _c(
                            "v-list-item",
                            {
                              key: index,
                              attrs: { link: "" },
                              on: {
                                click: function ($event) {
                                  return action.method(item)
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                { staticClass: "mr-4" },
                                [_c("v-icon", [_vm._v(_vm._s(action.icon))])],
                                1
                              ),
                              _c("v-list-item-subtitle", [
                                _vm._v(_vm._s(action.text)),
                              ]),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.batch_info`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": false,
                        "nudge-left": 50,
                        "offset-x": "",
                        "offset-y": "",
                        "open-on-hover": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        color: "primary",
                                        icon: "",
                                        tabindex: "-1",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.copyId(item)
                                        },
                                      },
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-information-variant"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-subtitle", [
                                        _vm._v("Batch ID"),
                                      ]),
                                      !_vm.isTprProRetailUser
                                        ? _c("v-list-item-title", [
                                            _vm._v(_vm._s(item.id)),
                                          ])
                                        : _c("v-list-item-title", [
                                            _vm._v(_vm._s(item.tpr_batch_id)),
                                          ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("v-divider"),
                  _c(
                    "v-toolbar",
                    {
                      staticStyle: { "margin-top": "8px" },
                      attrs: { flat: "", color: "#fff" },
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticStyle: { "padding-bottom": "3px" },
                          attrs: { align: "center" },
                        },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            {
                              staticStyle: { "max-width": "150px" },
                              attrs: { cols: "2" },
                            },
                            [
                              _c("v-select", {
                                staticClass: "mt-7",
                                attrs: {
                                  items: _vm.pageSizes,
                                  "menu-props": {
                                    top: true,
                                    offsetY: true,
                                    maxHeight: 500,
                                  },
                                  dense: "",
                                  label: "Items Per Page",
                                },
                                model: {
                                  value: _vm.pageSize,
                                  callback: function ($$v) {
                                    _vm.pageSize = $$v
                                  },
                                  expression: "pageSize",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2",
                              attrs: {
                                disabled: _vm.disablePrevious,
                                icon: "",
                                large: "",
                              },
                              on: { click: _vm.previous },
                            },
                            [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                            1
                          ),
                          _c("small", [_vm._v("Page " + _vm._s(_vm.page))]),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2",
                              attrs: {
                                disabled: _vm.disableNext,
                                icon: "",
                                large: "",
                              },
                              on: { click: _vm.next },
                            },
                            [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c("AlertDialog", {
        attrs: {
          value: _vm.alertDialog,
          batch: _vm.selectedBatch,
          total: _vm.total,
        },
        on: {
          "update:value": function ($event) {
            _vm.alertDialog = $event
          },
          update: function ($event) {
            return _vm.batchUpdate($event)
          },
        },
      }),
      _c("EditNameDialog", {
        attrs: { value: _vm.editNameDialog, batch: _vm.selectedBatch },
        on: {
          "update:value": function ($event) {
            _vm.editNameDialog = $event
          },
          close: function ($event) {
            return _vm.closeEditNameModal()
          },
          update: function ($event) {
            return _vm.getBatches()
          },
        },
      }),
      _c("DeleteBatchDialog", {
        attrs: { value: _vm.deleteBatchDialog, batch: _vm.selectedBatch },
        on: {
          "update:value": function ($event) {
            _vm.deleteBatchDialog = $event
          },
          close: function ($event) {
            return _vm.closeDeleteBatchModal()
          },
          update: function ($event) {
            return _vm.getBatches()
          },
        },
      }),
      _c(
        "v-overlay",
        { attrs: { absolute: "", value: _vm.creating || _vm.exporting } },
        [
          _c(
            "v-progress-circular",
            { attrs: { indeterminate: "", size: "100" } },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.creating
                      ? "Creating..."
                      : _vm.exporting
                      ? "Exporting..."
                      : ""
                  ) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }