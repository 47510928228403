export const displayHelpers = {
  computed: {
    title() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl':
          return 'text-capitalize text-h5 accent--text font-weight-light'
        case 'lg':
          return 'text-capitalize text-h5 accent--text font-weight-light'
        case 'md':
          return 'text-capitalize text-h6 accent--text font-weight-light'
        default:
          return '5em'
      }
    },
    label() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl':
          return 'text-capitalize text-body-1 font-weight-medium'
        case 'lg':
          return 'text-capitalize text-body-2 font-weight-medium'
        case 'md':
          return 'text-capitalize text-body-2 font-weight-medium'
        default:
          return '5em'
      }
    },
    body() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl':
          return 'text-capitalize text-body-1 font-weight-light'
        case 'lg':
          return 'text-capitalize text-body-2 font-weight-light'
        case 'md':
          return 'text-capitalize text-body-2 font-weight-light'
        default:
          return '5em'
      }
    },
    table() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl':
          return 'text-body-1 font-weight-normal'
        case 'lg':
          return 'text-body-1 font-weight-normal'
        case 'md':
          return 'text-body-2 font-weight-normal'
        default:
          return '5em'
      }
    },
    floatingButton() {
      return `
        bottom: 75px;
        right: 25px; 
      `
    },
    tableButtons() {
      return `
        height: 25px;
        width: 25px;
      `
    }
  },
  methods: {
    formatDateDisplay(date) {
      return date ? this.moment(date).format(this.$dateConfig.date_display) : ''
    },
    formatDateTimestamp(date) {
      return date ? this.moment.utc(date).local().format(this.$dateConfig.timestamp) : ''
    },
    formatCurrencyDisplay(value) {
      return value ? parseFloat(value).toFixed(2) : '0.00'
    },
    formatDateNoTimezone(date) {
      // e.g. "12/14/2021"
      const dateStr = new Date(date).toISOString().substr(0, 10)
      const [year, month, day] = dateStr.split('-')
      return [month, day, year].join('/')
    },
    formatDateTimeAlt(date) {
      // e.g. "12/14/21, 1:30 PM" (using local timezone)
      return new Date(date).toLocaleString('en', {
        dateStyle: 'short',
        timeStyle: 'short'
      })
    },
    formatDateISO(date) {
      // e.g. 2022-01-18
      if (date) return new Date(date).toISOString().substr(0, 10)
    },
    getFutureDate(numDaysToAdd) {
      const today = new Date().setHours(0, 0, 0, 0)
      const dateObj = new Date(today)
      dateObj.setDate(dateObj.getDate() + numDaysToAdd)
      return dateObj.toISOString().substr(0, 10)
    }
  }
}