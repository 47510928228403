import { getInstance } from './auth0-plugin'

class AuthToken {
  async setAuth (requestObj, tokenOptions = {}) {
    const authService = getInstance()
    try {
      const token = await authService.getTokenSilently(tokenOptions)
      requestObj.defaults.headers.common.Authorization = `Bearer ${token}`
    } catch (err) {
      console.error(err)
      if (err.message && err.message.toLowerCase().includes('consent required')) {
        await authService.loginWithRedirect({
          audience: tokenOptions.audience,
          redirect_uri: window.location.origin
        })
        return await this.setAuth(requestObj, tokenOptions)
      }
    }
  }
}

export default new AuthToken()
