import store  from '@/store/index'

export const notification = {
  methods: {
    notify (message) {
      store.dispatch('setSnackbar', { status: 'success', text: message} )
    },
    alert (message) {
      store.dispatch('setSnackbar', { status: 'error', text: message} )
    }
  }
}
