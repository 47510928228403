import axios from 'axios'
import AuthToken from '@/auth/auth-token'


class Items {
  constructor() {
    const base = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json'
      }
    })
    this.tprs = base
  }

  async getItems(batch_id, itemSearchVM, limit = 100, offset = 0) {
      await AuthToken.setAuth(this.tprs)
      return this.tprs({
        url: `/tpr/tpr_search/${batch_id}`,  
        method: 'POST',
        data: itemSearchVM, 
        params: {
          limit: limit,
          offset: offset
        }  
      })
  }

  async getUniqueValues(batch_id, column) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: `/tpr/unique_batch_values/${batch_id}`,
      method: 'POST',
      params: {
        column: column 
      }
    })
  }

  async getPromosByRow(batchId, tprId) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: '/tpr/rolled_promos',
      method: 'GET',
      params: {
        batch_id: batchId,
        tpr_mgmt_id: tprId,
        limit: 100,
        offset: 0
      }
    })
  }

  async tprUpdate(requestBody) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: '/tpr/tpr_report/update',
      method: 'PUT',
      data: requestBody
    })
  }

  async tprDelete(tprId) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: `/tpr/tpr_report/${tprId}`,
      method: 'DELETE',
    })
  }

  async uploadTprSrp(data, adGroups) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: `/tpr/tpr_file/upload`,
      method: 'POST',
      headers: {
        ...this.headers,
        'Content-Type': 'multipart/form-data'
      },
      data: data,
      params: { ad_group_ids: adGroups }
    })
  }
}

export default new Items()
