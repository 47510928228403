<template>
  <v-dialog :value="value" persistent width="500">
    <v-card>
      <v-card-title class="text-subtitle-1">
        <v-icon class="mr-2" >mdi-pencil</v-icon>
        Edit TPR Batch Name
      </v-card-title>
      <v-card-text>
        <v-text-field
          background-color="grey lighten-3"
          color="primary"
          dense
          flat
          hide-details
          :placeholder="batch.name"
          v-model="newBatchName"
          :rules="[rules.required]"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn :class="body" text @click="closeModal()">Cancel</v-btn>
        <v-btn
          :loading="loading"
          :class="body"
          class="green white--text"
          @click="confirm"
          :disabled="!newBatchName"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { displayHelpers } from '@/mixins/displayHelpers'
import { userAccess } from '@/mixins/user-access'
import Batches from '@/axios/batches'
export default {
  name: 'EditNameDialog',
  mixins: [displayHelpers, userAccess],
  props: { value: Boolean, batch: Object },
  data() {
    return {
      newBatchName: '',
      loading: false,
      rules: {
        required: value => !!value || 'Batch name is required'
      }
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.newBatchName = this.batch.batch_name;
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    async confirm() {
      if (!this.newBatchName) return;
      this.loading = true
      try {
          await Batches.updateBatchName(this.batch.id, this.newBatchName)
        } catch (err) {
          console.error(err)
          const message = err?.response?.data?.message || err.message
          this.alert(`Error Updating Batch Name: ${message}`)
        } finally {
          this.loading = false
          this.$emit('update')
          this.closeModal()
        }
    }
  }
}
</script>
