<template>
  <v-dialog :value="value" persistent width="500">
    <v-card>
      <v-card-title class="text-subtitle-1">
        <v-icon class="red--text mr-2" size="50">mdi-alert-octagon</v-icon>
        Overwrite TPR changes?
      </v-card-title>
      <v-card-text>
        <v-row justify="center">
          <v-col cols="10">
            This action will sync with the promotion portal to retrieve
            promotions that have a start date matching
            <b class="primary--text">{{ formatDateDisplay(batch.tpr_date) }}</b>
            and overwrite any changes in the selected TPR batch.
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="10"> Click <b>Confirm</b> to begin sync. </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn :class="body" text @click="$emit('update:value', false)"
          >Cancel</v-btn
        >
        <v-btn
          :loading="loading"
          :class="body"
          class="green white--text"
          @click="confirm">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { displayHelpers } from '@/mixins/displayHelpers'

export default {
  name: 'AlertDialog',
  mixins: [displayHelpers],
  props: { value: Boolean, batch: Object, total: String },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async confirm() {
      this.loading = true
      this.$emit('update', this.batch)
      this.$emit('update:value', false)
    }
  }
}
</script>
