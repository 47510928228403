export const utils = {
  filters: {
    format: function (value) {
      if (value == null) return '0.00'
      return parseFloat(value).toFixed(2)
    }
  },
  methods: {
    async getAllSettled (promises, returnErrors = false) {
      const rejected = []
      const results = await Promise.allSettled(promises)
      const fulfilled = results.flatMap(result => {
        if (result.status === 'rejected') {
          rejected.push(result)
          return []
        }
        return result.value
      })
      if (rejected.length > 0) console.log('Rejected promises:', rejected)
      if (returnErrors) {
        return { fulfilled, rejected }
      }
      return fulfilled
    }
  }
}
