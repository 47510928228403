export const fullwidth = {
  data: () => ({
    containerWidth: 0,
    loading: false,
    nonTableHeight: 425,
    showDialog: false,
    tableSize: 0,
    windowHeight: 0
  }),

  watch: {
    windowHeight: {
      handler () {
        this.tableSize = this.windowHeight - this.nonTableHeight
      }
    }
  },

  created () {
    this.tableSize = this.windowHeight - this.nonTableHeight
    window.addEventListener('resize', this.resize)
    this.resize()
  },

  methods: {
    resize () {
      this.windowHeight = window.innerHeight
    }
  }
}
